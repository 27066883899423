import React from 'react';
import styles from './center.module.css';

/**
 * Centers children both vertically and horizontally.
 */
export const Center: React.FC = (props) => {
  return (
    <div className={styles.center}>
      {props.children}
    </div>
  );
};