import React from "react";
import { Page, PagePadding, PageHorizontalPadding, PageTitle, ContentPadding } from "../../helper/page";
import { ConstrainedWidth } from "../../helper/constrainedWidth";
import { NavBar } from "../navigation/navBar";
import Policy from "./policy";
import { Attribution } from "../contact/attribution";
import '../legal.module.css';

export const PrivacyPolicy: React.FC = (props) => {
  return (
    <React.Fragment>
      <NavBar />
      <Page padTop={false}>
        <ConstrainedWidth>
          <PagePadding>
            <PageHorizontalPadding>
              <PageTitle title="Privacy Policy" color='black'/>
            </PageHorizontalPadding>
            <ContentPadding>
              <Policy />
            </ContentPadding>
            <Attribution/>
          </PagePadding>
        </ConstrainedWidth>
      </Page>
    </React.Fragment>
  );
}