import React from 'react';
import './App.css';
import { Home } from './components/page/home/home';
import { PrivacyPolicy } from './components/page/privacyPolicy/privacyPolicy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ScrollToTop from './components/helper/scrollToTop';
import { TermsAndConditions } from './components/page/termsAndConditions/termsAndConditions';
import { BackgroundColor } from './components/helper/background';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/privacy">
            <BackgroundColor color='white'>
              <PrivacyPolicy/>
            </BackgroundColor>
          </Route>
          <Route path="/terms">
            <BackgroundColor color='white'>
              <TermsAndConditions/>
            </BackgroundColor>
          </Route>
          <Route path="*">
            <BackgroundColor color='#333333'>
              <Home/>
            </BackgroundColor>
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
export default App;
