import React from "react";
import { Column } from "../../helper/column";
import { VerticalSpacer } from "../../helper/sizedBox";
import styles from './quote.module.css';

export interface QuoteProps {
  text: string 
  attribution: string 
  platform: string 
  className?: string
}

export const Quote: React.FC<QuoteProps> = (props) => {
  return (
    <div className={props.className}>
      <Column>
        <div className={styles.quoteIcon}>
          "
        </div>
        <div className={styles.quoteText}>
          {props.text}
        </div>
        <VerticalSpacer multipler={0.5}/>
        <div className={styles.quoteAttribution}>
          - {props.attribution}, {props.platform}
        </div>
      </Column>
    </div>
  );
};