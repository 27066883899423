import React from "react"

export interface BackgroundColorProps {
  color: string 
}

export class BackgroundColor extends React.Component<BackgroundColorProps, any> {
  componentDidMount() {
    this.updateColor();
  }

  componentDidUpdate() {
    this.updateColor();
  }

  private updateColor() {
    document.body.style.backgroundColor = this.props.color;
  }

  render() {
    return this.props.children
  }
}
