import React from 'react';
import styles from './expanded.module.css';

/**
 * Expands to fill space in flex.
 */
export const Expanded: React.FC = (props) => {
  return (
    <div className={styles.expanded}>
      {props.children}
    </div>
  );
};