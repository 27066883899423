
/** 
 * @return copy of `xs` but reversed.
 */
export function reversed<T>(xs: T[]): T[] {
  const ys = [...xs];
  ys.reverse();
  return ys;
}

export function sep<T>(xs: T[], sep: T): T[] {
  let rs: T[] = [];
  for (let i=0; i<xs.length; i++) {
    rs.push(xs[i]);
    rs.push(sep);
  }
  return rs;
}

export function shuffle<T>(array: T[]): T[] {
  const cpy = [...array];
  shuffleInPlace(cpy);
  return cpy;
}

function shuffleInPlace<T>(array: T[]) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    const temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
