import { PageTitle, PageSubtitle, PageHorizontalPadding } from '../../helper/page';
import { matchScreenWidth } from "../../helper/breakpoint";
import { WrapSpacing, Wrap } from "../../helper/wrap";
import React from 'react';
import styles from './contactLinks.module.css';
import { VerticalSpacer } from '../../helper/sizedBox';
import { Column } from '../../helper/column';
import { Alignment } from '../../helper/alignment';
import twitter from './twitter.png';
import instagram from './instagram.png';
import facebook from './facebook.png';

export const ContactLinks: React.FC = (props) => {
  return (
    <PageHorizontalPadding>
      <Column crossAxisAlignment={Alignment.start}>
        <PageTitle title="Contact us"/>
        <PageSubtitle subtitle="Got any questions? We'd love to hear from you! Just email us at ">
          <EmailLink/>
        </PageSubtitle>
        <VerticalSpacer multipler={2.5}/>
        <PageSubtitle subtitle="Also, check out our social media"/>
        <VerticalSpacer multipler={1.5}/>
        <SocialMediaLinks>
          <SocialMediaLink 
            src={instagram}
            href="https://www.instagram.com/playdoodledash"/>
          <SocialMediaLink 
            src={facebook}
            href="https://www.facebook.com/playdoodledash"/>
          <SocialMediaLink 
            src={twitter} 
            scale={1.12}
            href="https://twitter.com/playdoodledash"/>
        </SocialMediaLinks>
      </Column>
    </PageHorizontalPadding>
  );
};

const EmailLink: React.FC = (props) => {
  return (
    <a href="mailto:hello@doodledash.app">
      hello@doodledash.app
    </a>
  );
};

const SocialMediaLinks: React.FC = (props) => {
  const spacing = matchScreenWidth({
    onDesktop: () => WrapSpacing.start,
    onPhone: () => WrapSpacing.spaceBetween
  });
  return (
    <Wrap spacing={spacing}>
      {props.children}
    </Wrap>
  );
};

interface SocialMediaLinkProps {
  src: string
  scale?: number
  href: string
}

const SocialMediaLink: React.FC<SocialMediaLinkProps> = (props) => {
  const size = matchScreenWidth({
    onDesktop: () => 55,
    onPhone: () => 50
  });
  const style: React.CSSProperties = {
    width: size * (props.scale ?? 1.0),
    height: size * (props.scale ?? 1.0),
    marginRight: size*0.5,
    marginBottom: size*0.5
  };
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      <img src={props.src} alt="" className={styles.socialMediaLink} style={style}/>
    </a>
  );
};