import React from 'react';
import { NavBar } from '../navigation/navBar';
import { Hero } from '../hero/hero';
import { Drawings, drawingsId } from '../drawings/drawings';
import { HowToPlay, howToPlayId } from '../howToPlay/howToPlay';
import { Contact, contactId } from '../contact/contact';
import { NavLink } from '../navigation/navLink';
import { Desktop } from '../../helper/breakpoint';
import { appBlue } from '../../helper/colors';
import { reviewsId, Reviews } from '../reviews/reviews';

export const Home: React.FC = (props) => {
  return (
    <React.Fragment>
      <Desktop>
        <NavBar color={appBlue} showAppIcon={false}>
          <NavLink 
            text="How to play"
            scrollToId={howToPlayId}/>
          <NavLink 
            text="Drawings"
            scrollToId={drawingsId}/>
          <NavLink
            text="Reviews"
            scrollToId={reviewsId}/>
          <NavLink 
            text="Contact"
            scrollToId={contactId}/>
        </NavBar>
      </Desktop>
      <Hero/>
      <HowToPlay/>
      <Drawings/>
      <Reviews/>
      <Contact/>
    </React.Fragment>
  );
}