import React from 'react';
import styles from './navBar.module.css';
import { AppName } from './appName';
import { ConstrainedWidth } from '../../helper/constrainedWidth';
import { Row } from '../../helper/row';
import { Expanded } from '../../helper/expanded';
import { Desktop } from '../../helper/breakpoint';
import { PageHorizontalPadding } from '../../helper/page';
import { Link } from 'react-router-dom';

export interface NavBarProps {
  showAppIcon?: boolean
  color?: string 
}

export const NavBar: React.FC<NavBarProps> = (props) => {
  return (
    <div style={{backgroundColor: props.color ?? 'transparent'}} className={styles.navBar}>
      <ConstrainedWidth>
        <PageHorizontalPadding>
          <Row>
            {
              (props.showAppIcon ?? true)
                ? <Link to='/' style={{textDecoration: 'none'}}>
                    <AppName/>
                  </Link>
                : <React.Fragment/>
            }
            <Desktop>
              <Expanded/>
              {props.children}
            </Desktop>
          </Row>
        </PageHorizontalPadding>
      </ConstrainedWidth>
    </div>
  );
};