
export enum Alignment {
  start, center, end
}

export interface AlignmentMatcher<T> {
  onStart: () => T
  onCenter: () => T
  onEnd: () => T
}

export function matchAlignment<T>(alignment: Alignment, matcher: AlignmentMatcher<T>): T {
  switch (alignment) {
    case Alignment.start: return matcher.onStart();
    case Alignment.center: return matcher.onCenter();
    case Alignment.end: return matcher.onEnd();
  }
}

export function alignmentToFlexCss(alignment: Alignment): string {
  return matchAlignment(alignment, {
    onStart: () => 'flex-start',
    onCenter: () => 'center',
    onEnd: () => 'flex-end'
  });
}