import { matchScreenWidth } from '../../helper/breakpoint';
import React from 'react';
import { Column } from '../../helper/column';
import { VerticalSpacer } from '../../helper/sizedBox';
import styles from './drawing.module.css';

export interface DrawingProps {
  title: string
  angleRad: number
  src: string
}

export const Drawing: React.FC<DrawingProps> = (props) => {
  const drawingClass = matchScreenWidth({
    onDesktop: () => styles.drawingDesktop,
    onPhone: () => styles.drawingPhone
  });
  return (
    <div className={styles.drawingContainer}>
      <Column>
        <ScaleRotated rotationRads={props.angleRad}>
          <img src={props.src} alt="" className={`${styles.drawing} ${drawingClass}`}/>
        </ScaleRotated>
        <VerticalSpacer/>
        <div className={styles.drawingTitle}>
          {props.title}
        </div>
      </Column>
    </div>
  );
};

interface ScaleRotatedProps {
  rotationRads: number
}

/**
 * Rotates children by a given angle, and scales them to ensure they would fit
 * in the square made if they were all rotated to 0.
 */
const ScaleRotated: React.FC<ScaleRotatedProps> = (props) => {
  const style: React.CSSProperties = {
    transform: `rotate(${props.rotationRads}rad) scale(${scale(props.rotationRads)})`
  };
  return (
    <div style={style}>
      {props.children}
    </div>
  );
};

function scale(angleRad: number): number {
  const theta = Math.abs(angleRad % (Math.PI/2));
  return 1.0 / (Math.sin(theta) + Math.cos(theta));
}
