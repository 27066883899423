import React from 'react';
import styles from './row.module.css';
import { Alignment, alignmentToFlexCss } from './alignment';
import { mapUndef } from '../../helper/null';

export interface RowProps {
  mainAxisAlignment?: Alignment
  crossAxisAlignment?: Alignment
}

export const Row: React.FC<RowProps> = (props) => {
  const style: React.CSSProperties = {
    alignItems: alignmentToFlexCss(props.mainAxisAlignment ?? Alignment.center),
    justifyContent: mapUndef(props.crossAxisAlignment, alignmentToFlexCss),
  };
  return (
    <div className={styles.row} style={style}>
      {props.children}
    </div>
  );
};