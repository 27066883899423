import { Page, PagePadding } from '../../helper/page';
import React from 'react';
import styles from './contact.module.css';
import { ConstrainedWidth } from "../../helper/constrainedWidth"
import { SplitScreen } from '../../helper/splitScreen';
import { ContactLinks } from './contactLinks';
import { BoringStuff } from './boringStuff';
import { VerticalSpacer } from '../../helper/sizedBox';
import { Phone } from '../../helper/breakpoint';
import { Column } from '../../helper/column';
import { Attribution } from './attribution';
import { Alignment } from '../../helper/alignment';

export const contactId = 'contact';

export const Contact: React.FC = (props) => {
  return (
    <div id={contactId}>
      <Page
        className={styles.contact}>
        <ConstrainedWidth>
          <PagePadding>
            <Column crossAxisAlignment={Alignment.start}>
              <SplitScreen>
                <PaddedContactLinks/>
                <BoringStuff/>
              </SplitScreen>
              <Attribution/>
            </Column>
            <VerticalSpacer/>
          </PagePadding>
        </ConstrainedWidth>
      </Page>
    </div>
  );
};

const PaddedContactLinks: React.FC = (props) => {
  return (
    <React.Fragment>
      <ContactLinks/>
      <Phone>
        <VerticalSpacer multipler={3.0}/>
      </Phone>
    </React.Fragment>
  );
};