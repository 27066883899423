import React from 'react';
import styles from './constrainedWidth.module.css';
import { Column } from './column';

export const maxPageWidth = '1300px';

/**
 * Adds max width to children.
 */
export const ConstrainedWidth: React.FC = (props) => {
  return (
    <Column>
      <div className={styles.constrainedWidth} style={{maxWidth: maxPageWidth}}>
        {props.children}
      </div>
    </Column>
  );
};