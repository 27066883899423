import React from 'react';
import { Column } from '../../helper/column';
import { VerticalSpacer, HorizontalSpacer } from '../../helper/sizedBox';
import { Row } from '../../helper/row';
import pencil from './pencil.svg';
import styles from './features.module.css';

export const Features: React.FC = (props) => {
  return (
    <Column>
      <Feature text='No artistic skills required, just imagination!'/>
      <VerticalSpacer/>
      <Feature text='Unlock and collect a variety of paint colours'/>
      <VerticalSpacer/>
      <Feature text='Laugh seeing your friends’ drawings'/>
    </Column>
  );
};

interface FeatureProps {
  text: string
}

const Feature: React.FC<FeatureProps> = (props) => {
  return (
    <Row>
      <PencilIcon/>
      <HorizontalSpacer multipler={0.5}/>
      <div className={styles.featureText}>
        {props.text}
      </div>
      <HorizontalSpacer multipler={0.5}/>
      <PencilIcon/>
    </Row>
  );
};

const PencilIcon: React.FC = (props) => {
  return <img src={pencil} alt='' className={styles.pencil}/>
};