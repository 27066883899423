import React from 'react';
import styles from './wrap.module.css';
import { mapUndef } from '../../helper/null';

export enum WrapSpacing {
  start, spaceBetween, spaceAround
}

export interface WrapSpacingMatcher<T> {
  onStart: () => T
  onSpaceBetween: () => T
  onSpaceAround: () => T 
}

export function matchWrapSpacing<T>(spacing: WrapSpacing, matcher: WrapSpacingMatcher<T>): T {
  switch (spacing) {
    case WrapSpacing.start: return matcher.onStart();
    case WrapSpacing.spaceBetween: return matcher.onSpaceBetween();
    case WrapSpacing.spaceAround: return matcher.onSpaceAround();
  }
}

export interface WrapProps {
  spacing?: WrapSpacing
}

export const Wrap: React.FC<WrapProps> = (props) => {
  const style: React.CSSProperties = {
    justifyContent: mapUndef(props.spacing, spacing => matchWrapSpacing(spacing, {
      onStart: () => 'flex-start',
      onSpaceBetween: () => 'space-between',
      onSpaceAround: () => 'space-around'
    }))
  };
  return (
    <div className={styles.wrap} style={style}> 
      {props.children}
    </div>
  );
};