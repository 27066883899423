import React from 'react';
import styles from './column.module.css';
import { Alignment, alignmentToFlexCss } from './alignment';
import { mapUndef } from '../../helper/null';

export interface ColumnProps {
  mainAxisAlignment?: Alignment
  crossAxisAlignment?: Alignment
}

export const Column: React.FC<ColumnProps> = (props) => {
  const style: React.CSSProperties = {
    justifyContent: mapUndef(props.mainAxisAlignment, alignmentToFlexCss),
    alignItems: alignmentToFlexCss(props.crossAxisAlignment ?? Alignment.center),
  };
  return (
    <div className={styles.column} style={style}>
      {props.children}
    </div>
  );
};
