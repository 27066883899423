import React from 'react';
import { Row } from '../../helper/row';
import { Column } from '../../helper/column';
import styles from './stats.module.css';
import { Expanded } from '../../helper/expanded';
import { Center } from '../../helper/center';
import { HorizontalSpacer, VerticalSpacer } from '../../helper/sizedBox';
import star from './star.svg';
import { matchScreenWidth } from '../../helper/breakpoint';

export const Stats: React.FC = (props) => {
  return (
    <Row>
      <Stat>
        <ReviewScore/>
      </Stat>
      <VerticalDivider/>
      <Stat>
        <Downloads/>
      </Stat>
      <VerticalDivider/>
      <Stat>
        <New/>
      </Stat>
    </Row>
  );
};

const ReviewScore: React.FC = (props) => {
  return (
    <Column>
      <Row>
        <StatHeader>
          4.5
        </StatHeader>
        <HorizontalSpacer multipler={0.5}/>
        <img src={star} alt="" className={styles.star}/>
      </Row>
      <VerticalSpacer multipler={0.5}/>
      <StatSubheader>
        20+ reviews
      </StatSubheader>
    </Column>
  );
};

const Downloads: React.FC = (props) => {
  return (
    <Column>
      <StatHeader>
        1000+
      </StatHeader>
      <VerticalSpacer multipler={0.5}/>
      <StatSubheader>
        downloads
      </StatSubheader>
    </Column>
  );
};

const New: React.FC = (props) => {
  return (
    <Column>
      <StatHeader>
        New
      </StatHeader>
      <VerticalSpacer multipler={0.5}/>
      <StatSubheader>
        Just released!
      </StatSubheader>
    </Column>
  );
};

const StatHeader: React.FC = (props) => {
  return (
    <div className={styles.statHeader}>
      {props.children}
    </div>
  );
};

const StatSubheader: React.FC = (props) => {
  return (
    <div className={styles.statSubheader}>
      {props.children}
    </div>
  );
};

const Stat: React.FC = (props) => {
  return (
    <Expanded>
      <Center>
        {props.children}
      </Center>
    </Expanded>
  )
};

const VerticalDivider: React.FC = (props) => {
  const spacerMultipler = matchScreenWidth({
    onDesktop: () => 2.0,
    onPhone: () => 1.0
  });
  return (
    <Row>
      <HorizontalSpacer multipler={spacerMultipler}/>
      <div className={styles.verticalDivider}/>
      <HorizontalSpacer multipler={spacerMultipler}/>
    </Row>
  );
}