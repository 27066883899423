import React from 'react';
import { SplitScreen } from '../../helper/splitScreen';
import styles from './howToPlay.module.css';
import { Page, ContentPadding, PageTitle, PagePadding, PageHorizontalPadding, PageSubtitle } from '../../helper/page';
import { Column } from '../../helper/column';
import { Phone, matchScreenWidth } from '../../helper/breakpoint';
import { VerticalSpacer } from '../../helper/sizedBox';
import { ConstrainedWidth } from '../../helper/constrainedWidth';
import scribble from './scribble.png';
import complete1 from './complete1.png';
import complete2 from './complete2.png';
import winner from './winner.png';
import { InstructionsPhone } from './phone';
import { appRed } from '../../helper/colors';

export const howToPlayId = 'howToPlay';

/**
 * Displays images of phones with text about how to play.
 */
export const HowToPlay: React.FC = (props) => {
  return (
    <div id="howToPlay">
      <Page
        backgroundColor={appRed}>
          <ConstrainedWidth>
            <PagePadding>
              <PageHorizontalPadding>
                <PageTitle title="How do I play?"/>
                <PageSubtitle subtitle="Race your friends and the clock to turn random doodles into works of art! 🎨🖌"/>
              </PageHorizontalPadding>
              <ContentPadding>
                <SplitScreen>
                  <ScribblerInstructions/>
                  <CompleterInstructions/>
                  <WinnerInstructions/>
                </SplitScreen>
              </ContentPadding>
            </PagePadding>
          </ConstrainedWidth>
      </Page>
    </div>
  );
};

const ScribblerInstructions: React.FC = (props) => {
  return (
    <Instructions text="A player draws a random doodle!">
      <InstructionsPhone src={scribble} className={styles.scribblerPhone}/>
    </Instructions>
  );
};

const CompleterInstructions: React.FC = (props) => {
  return (
    <Instructions text="Everyone has 2 mins to turns the doodle into a finished drawing and add a hilarious caption!">
      <InstructionsPhone src={complete2} className={styles.backgroundCompleterPhone}/>
      {/* 
        Below is a horrible hack: this phone is translate -100% in Y, therefore 
        set height to 0 to stop the text being pushed down.
      */}
      <div style={{height: '0'}}>
        <InstructionsPhone src={complete1} className={styles.foregroundCompleterPhone}/>
      </div>
    </Instructions>
  );
}

const WinnerInstructions: React.FC = (props) => {
  return (
    <Instructions text="A judge chooses their favourite!" isBottom={true}>
      <InstructionsPhone src={winner} className={styles.winnerP}/>
    </Instructions>
  );
};

interface InstructionsProps {
  text: string
  isBottom?: boolean 
}

const Instructions: React.FC<InstructionsProps> = (props) => {
  const text = <InstructionsText text={props.text}/>;
  const top = matchScreenWidth({
    onDesktop: () => props.children,
    onPhone: () => text,
  });
  const bottom = matchScreenWidth({
    onDesktop: () => text,
    onPhone: () => props.children
  });
  return (
    <Column>
      {top}
      <VerticalSpacer multipler={1.5}/>
      {bottom}
      {
        (props.isBottom ?? false) 
          ? <React.Fragment/>
          : <Phone><VerticalSpacer multipler={4.0}/></Phone>
      }
    </Column>
  );
};

interface InstructionsTextProps {
  text: string
}

const InstructionsText: React.FC<InstructionsTextProps> = (props) => {
  return (
    <div className={styles.instructionsText}>
      {props.text}
    </div>
  );
};