import React from 'react';
import icon from './icon.svg';
import styles from './appIcon.module.css';
import { Row } from '../../helper/row';
import { Column } from '../../helper/column';
import { HorizontalSpacer } from '../../helper/sizedBox';
import { Alignment } from '../../helper/alignment';
import { matchScreenWidth } from '../../helper/breakpoint';

export const AppIcon: React.FC = (props) => {
  return (
    <Row>
      <img src={icon} alt='' className={styles.icon}/>
      <HorizontalSpacer/>
      <Column crossAxisAlignment={Alignment.start}>
        <div className={styles.appName}>
          Doodle Dash
        </div>
        <div className={styles.tagline}>
          {
            matchScreenWidth({
              onDesktop: () => 'Turn random scribbles into art!',
              onPhone: () => 'Turn scribbles into art!',
            })
          }
        </div>
      </Column>
    </Row>
  );
};