import React from "react";
import { Page, PagePadding, PageHorizontalPadding, PageTitle, ContentPadding, PageSubtitle } from "../../helper/page";
import { ConstrainedWidth } from "../../helper/constrainedWidth";
import { appBlue } from '../../helper/colors';
import { Quote } from './quote';
import { VideoReview } from "./videoReview";
import styles from './reviews.module.css';
import { Desktop, Phone } from '../../helper/breakpoint';
import { Column } from "../../helper/column";
import { VerticalSpacer } from "../../helper/sizedBox";
import { Wrap, WrapSpacing } from '../../helper/wrap';

export const reviewsId = 'reviews';

export const Reviews: React.FC = (props) => {
  return (
    <div id={reviewsId}>
      <Page
        backgroundColor={appBlue}>
          <ConstrainedWidth>
            <PagePadding>
              <PageHorizontalPadding>
                <PageTitle title="What do people think?"/>
                {/* <PageSubtitle subtitle="A few reviews of what players thought 😄"/> */}
              </PageHorizontalPadding>
              <ContentPadding>
                <div className={styles.reviews}>
                  <RowOrCol>
                    <Quote 
                      className={styles.review}
                      text="A very fun and novel idea!" 
                      attribution="Tomos Evans"
                      platform="Android"
                    />
                    <Spacer/>
                    <VideoReview 
                      className={styles.review}
                      url="https://www.youtube.com/embed/FTV18ZN3l6M?controls=0&amp;start=145"
                      attribution='ShawnMHowell'
                    />
                    <Spacer/>
                    <Quote
                      className={styles.review}
                      text="Love playing this game with my friends during lockdown" 
                      attribution="Eve"
                      platform="iOS"
                    />
                    <Spacer/>
                    <Quote
                      className={styles.review}
                      text="I love the amount of colours available and seeing everyone’s drawings" 
                      attribution="PippaSaunders123"
                      platform="iOS"
                    />
                    <Spacer/>
                    <Quote
                      className={styles.review}
                      text="Great game - so much fun!" 
                      attribution="Olivia"
                      platform="iOS"
                    />
                  </RowOrCol>
                </div>
              </ContentPadding>
            </PagePadding>
          </ConstrainedWidth>
      </Page>
    </div>
  );
} 

const RowOrCol: React.FC = (props) => {
  return (
    <React.Fragment>
      <Desktop>
        <Wrap spacing={WrapSpacing.spaceAround}>
          {props.children}
        </Wrap>
      </Desktop>
      <Phone>
        <Column>
          {props.children}
        </Column>
      </Phone>
    </React.Fragment>
  );
};

const Spacer: React.FC = (props) => {
  return (
    <Phone>
      <VerticalSpacer multipler={2.0}/>
    </Phone>
  )
};