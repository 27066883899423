import React from 'react';
import styles from './appName.module.css';
import { Row } from '../../helper/row';
import { HorizontalSpacer } from '../../helper/sizedBox';
import icon from './icon_circle.png';

export const AppName: React.FC = (props) => {
  return (
    <Row>
      <AppIcon/>
      <HorizontalSpacer/>
      <div className={styles.appName}>
        Doodle Dash
      </div>
    </Row>
  );
};

const AppIcon: React.FC = (props) => {
  return (
    <img src={icon} alt="" className={styles.appIcon}/>
  );
};