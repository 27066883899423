import React from 'react';
import styles from './heroText.module.css';
import { VerticalSpacer } from '../../helper/sizedBox';
import { Column } from '../../helper/column';
import { Stats } from './stats';
import { DownloadCallToAction } from '../../helper/downloadCallToAction';
import { Features } from './features';
import { Desktop } from '../../helper/breakpoint';
import { AppIcon } from './appIcon';

/**
 * Message that the app will be released soon, and a sign up form where 
 * the user can enter an email to be notified when the app is released.
 */
export const HeroText: React.FC = (props) => {
  return (
    <div className={styles.hero}>
      <Column>
        <AppIcon/>
        <VerticalSpacer multipler={3.0} />
        <Stats/>
        <VerticalSpacer multipler={3.0} />
        <DownloadCallToAction/>
        <VerticalSpacer multipler={3.0} />
        <Desktop>
          <Features/>
        </Desktop>
        <Desktop>
          <VerticalSpacer multipler={3.0}/>
        </Desktop>
      </Column>
    </div>
  );
};
