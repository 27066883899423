import React from 'react';
import styles from './navLink.module.css';

export interface NavLinkProps {
  text: string
  scrollToId: string
}

/**
 * Used to navigate to a location on the site.
 */
export const NavLink: React.FC<NavLinkProps> = (props) => {
  return (
    <div className={styles.navLink} onPointerDown={scrollToElement(props.scrollToId)}>
      {props.text}
    </div>
  );
};

function scrollToElement(id: string): () => void {
  return () => {
    const elem = document.getElementById(id);
    if (elem == null) {
      throw new Error(`Could not find element for id ${id}`);
    }
    elem.scrollIntoView({
      behavior: "smooth"
    });
  };
}
