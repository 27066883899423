import React from 'react';
import { Desktop, Phone } from './breakpoint';
import Rect from 'react';
import styles from './splitScreen.module.css';

export enum SplitScreenOrdering {
  forwards,
  backwards
}

function isForwards(ordering: SplitScreenOrdering): boolean {
  return ordering === SplitScreenOrdering.forwards;
}

export interface SplitScreenProps {
  horizontalOrdering?: SplitScreenOrdering
  verticalOrdering?: SplitScreenOrdering
}

/**
 * @return `props.child1` and `props.child2` ordered according to 
 *  `props.horizontalOrdering` if the screen size is large enough. Otherwise,
 *  arranges the children vertically and orders them according to 
 *  `props.verticalOrdering`.
 */
export const SplitScreen: React.FC<SplitScreenProps> = (props) => {
  return (
    <React.Fragment>
      <Desktop>
        <div className={styles.orderingHorizontal}>
          <Ordered ordering={props.horizontalOrdering ?? SplitScreenOrdering.forwards}>
            {props.children}
          </Ordered>
        </div>
      </Desktop>
      <Phone>
        <div className={styles.orderingVertical}>
          <Ordered ordering={props.verticalOrdering ?? SplitScreenOrdering.forwards}>
            {props.children}
          </Ordered>
        </div>
      </Phone>
    </React.Fragment>
  );
};

interface OrderedProps {
  ordering: SplitScreenOrdering
}

const Ordered: Rect.FC<OrderedProps> = (props) => {
  const cs = isForwards(props.ordering) && props.children !== undefined && props.children !== null
    ? React.Children.toArray(props.children)
    : React.Children.toArray(props.children).reverse();

  return (
    <React.Fragment>
      {cs.map(c => <Child>{c}</Child>)}
    </React.Fragment>
  );
}

const Child: React.FC = (props) => {
  return (
    <div className={styles.child}>
      {props.children}
    </div>
  );
}