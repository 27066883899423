import React from 'react';
import { PageTitle, PageHorizontalPadding } from '../../helper/page';
import style from './boringStuff.module.css';
import { Column } from '../../helper/column';
import { Alignment } from '../../helper/alignment';
import { Link } from 'react-router-dom';
import { VerticalSpacer } from '../../helper/sizedBox';

export const BoringStuff: React.FC = (props) => {
  return (
    <PageHorizontalPadding>
      <div className={style.boringStuff}>
        <PageTitle title="Boring stuff"/>
        <Column crossAxisAlignment={Alignment.start}>
          <Link to="/privacy">Privacy Policy</Link>
          <VerticalSpacer multipler={0.5}/>
          <Link to="/terms">Terms of Service</Link>
        </Column>
      </div>
    </PageHorizontalPadding>
  );
};