import React from "react";
import { Column } from "../../helper/column";
import { VerticalSpacer } from "../../helper/sizedBox";
import styles from './videoReview.module.css';

export interface VideoReviewProps {
  url: string 
  attribution: string 
  className?: string
}

export const VideoReview: React.FC<VideoReviewProps> = (props) => {
  return (
    <div className={props.className}>
      <Column>
        <iframe className={styles.videoReview}
          src={props.url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          frameBorder='0'
          allowFullScreen
          title='video'
        />
        <VerticalSpacer multipler={0.5}/>
        <div className={styles.videoAttribution}>
          - {props.attribution}
        </div>
      </Column>
    </div>
  );
};
