import React, { useState, useEffect } from 'react';
import { many } from './many';

const defaultBreakpoint = 570;

interface DesktopProps {
  breakpoint?: number
}

/**
 * @return container of children if the screen width is above a threshold.
 *  Otherwise, returns nothing.
 */
export const Desktop: React.FC<DesktopProps> = (props) => {
  return matchScreenWidth({
    onDesktop: () => many(props.children),
    onPhone: () => null
  }, props.breakpoint);
};

/**
 * @return container of children if the screen width is below a threshold.
 *  Otherwise, returns nothing.
 */
export const Phone: React.FC = (props) => {
  return matchScreenWidth({
    onPhone: () => many(props.children),
    onDesktop: () => null
  });
}

export interface MatchScreenWidthProps {
  breakpoint?: number
  onDesktop: () => React.ReactElement
  onPhone: () => React.ReactElement
}

export const MatchScreenWidth: React.FC<MatchScreenWidthProps> = (props) => {
  return matchScreenWidth({
    onDesktop: () => props.onDesktop(),
    onPhone: () => props.onPhone()
  });
};

export interface ScreenWidthMatcher<T> {
  onDesktop: () => T
  onPhone: () => T
}

export function matchScreenWidth<T>(matcher: ScreenWidthMatcher<T>, breakpoint?: number): T {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { width } = useWindowDimensions();
  return (width >= (breakpoint ?? defaultBreakpoint)) ? matcher.onDesktop() : matcher.onPhone();
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}