import React from 'react';
import { HeroText } from './heroText';
import { Page, ContentPadding } from '../../helper/page';
import { Desktop, matchScreenWidth } from '../../helper/breakpoint';
import { Row } from '../../helper/row';
import { ConstrainedWidth } from '../../helper/constrainedWidth'
import { VerticalSpacer, HorizontalSpacer } from '../../helper/sizedBox';
import { HeroPhone } from './heroPhone';
import { Center } from '../../helper/center';
import { appBlue } from '../../helper/colors';

export const Hero: React.FC = (props) => {
  const padTop = matchScreenWidth({
    onDesktop: () => false,
    onPhone: () => true
  });
  return (
    <Page 
      backgroundColor={appBlue}
      padTop={padTop}>
      <ConstrainedWidth>
        <ContentPadding padTop={false}>
          <VerticalSpacer multipler={1.5}/>
          <Row>
            <Center>
              <HeroText/>
            </Center>
            <Desktop breakpoint={900}>
              <HorizontalSpacer multipler={5.0}/>
              <Center>
                <HeroPhone/>
              </Center>
            </Desktop>
          </Row>
        </ContentPadding>
      </ConstrainedWidth>
    </Page>
  );
};