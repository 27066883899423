import React from 'react';

const defaultSize = 16.0;

export interface Width {
  width: number
}

export interface Height {
  height: number
}

export interface Multipler {
  multipler: number
}

export type SizedBoxProps = Partial<Width> & Partial<Height>;

export const SizedBox: React.FC<SizedBoxProps> = (props) => {
  const style: React.CSSProperties = {
    display: 'inline-block',
    width: `${props.width ?? defaultSize}px`,
    height: `${props.height ?? defaultSize}px`
  };
  return (
    <div style={style}>
    </div>
  );
};

export const HorizontalSpacer: React.FC<Partial<Multipler>> = (props) => {
  return <SizedBox width={defaultSize * (props.multipler ?? 1.0)}/>
};

export const VerticalSpacer: React.FC<Partial<Multipler>> = (props) => {
  return <SizedBox height={defaultSize * (props.multipler ?? 1.0)}/>
};