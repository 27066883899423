import React from 'react';
import styles from './attribution.module.css';
import { ContentPadding } from '../../helper/page';

export const Attribution: React.FC = (props) => {
  const year = new Date().getFullYear(); 
  return (
    <ContentPadding>
      <footer className={styles.copyright}>
        © {year} Alexander Baker-Smith. All rights reserved. 
      </footer>
    </ContentPadding>
  );
};