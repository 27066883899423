import styles from './page.module.css';
import React from 'react';
import useWindowDimensions from './breakpoint';
import { matchScreenWidth } from './breakpoint';

export const pagePadding = 40;

export interface PageProps {
  className?: string
  backgroundColor?: string 
  padTop?: boolean
}

export const Page: React.FC<PageProps> = (props) => {
  const { width } = useWindowDimensions();
  const borderPaddingHeight = props.padTop ?? true 
    ? width * borderAspectRatio * 0.55
    : 0.0;
    
  return (
    <div style={{backgroundColor: props.backgroundColor}}>
      <div className={props.className}>
        <div style={{height: `${borderPaddingHeight}px`}}/>
          <div className={styles.page} style={{zIndex: 1}}>
            {props.children}
          </div>
          {
            props.backgroundColor !== undefined 
              ? <Border color={props.backgroundColor}/>
              : <React.Fragment/>
          }
      </div>
    </div>
  );
};

export interface PageTitleProps {
  title: string
  color?: string
}

export const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <div className={styles.title} style={{color: props.color}}>
      {props.title}
    </div>
  );
};

export interface PageSubtitleProps {
  subtitle: string
}

export const PageSubtitle: React.FC<PageSubtitleProps> = (props) => {
  return (
    <div>
      {props.subtitle}
      {props.children}
    </div>
  );
}

export const PagePadding: React.FC = (props) => {
  const style: React.CSSProperties = {
    paddingTop: pagePadding,
    paddingBottom: pagePadding
  };
  return (
    <div style={style}>
      {props.children}
    </div>
  );
};

export const PageHorizontalPadding: React.FC = (props) => {
  return (
    <ContentPadding
      padTop={false}
      padBottom={false}>
        {props.children}
    </ContentPadding>
  );
};

export interface ContentPaddingProps {
  padTop?: boolean
  padBottom?: boolean 
  padLeft?: boolean
  padRight?: boolean
}

/**
 * @return padded children.
 */
export const ContentPadding: React.FC<ContentPaddingProps> = (props) => {
  const style: React.CSSProperties = {
    height: '100%',
    paddingTop:    (props.padTop    ?? true)  ? pagePadding : undefined,
    paddingBottom: (props.padBottom ?? false) ? pagePadding : undefined,
    paddingLeft:   (props.padLeft   ?? true)  ? pagePadding : undefined,
    paddingRight:  (props.padRight  ?? true)  ? pagePadding : undefined,
  };
  return (
    <div style={style}>
      {props.children}
    </div>
  );
};

const borderAspectRatio = 137.0 / 1024.0;

interface BorderProps {
  color: string 
}

const Border: React.FC<BorderProps> = (props) => {
  return matchScreenWidth({
    onDesktop: () => <BorderLarge color={props.color}/>,
    onPhone: () => <BorderSmall color={props.color}/>
  });
};

const BorderLarge: React.FC<BorderProps> = (props) => {
  // Make sure to add 'preserveAspectRatio="none"' if replacing SVG.
  // This ensures the SVG takes up the whole width on Safari.
  return (
    <svg className={styles.border} preserveAspectRatio="none" viewBox="0 0 1025 137"><defs><clipPath id="clip-path"><rect width="1025" height="137" fill="none"/></clipPath></defs><g clip-path="url(#clip-path)"><polyline points="-193.72 40.59 -2.9 124.31 -171.23 30.44 15.98 113.32 -120.18 40.59 31.85 99.79 -87.67 30.44 94.77 115.86 -54.24 30.44 137.04 119.24 -33.45 21.99 175.85 115.01 50.23 43.98 185.88 100.64 66.82 36.36 242.84 108.25 77.97 21.99 271.61 104.02 157.41 33.83 309.96 94.72 200.86 33.83 356.36 100.64 197.66 12.69 412.53 105.71 274.73 31.29 441.05 100.64 307.37 28.75 475.66 97.25 368.33 31.29 534.64 100.64 370.37 12.69 588.91 103.17 453.08 27.91 641.22 106.56 487.23 19.45 650.73 90.49 536.72 24.52 712.8 93.03 601.47 32.14 768.05 102.33 598.79 12.69 775.53 90.49 695.72 41.44 821.4 94.72 726.46 36.36 855.75 90.49 738.07 27.06 901.63 94.72 776.02 23.68 948.1 97.25 842.34 36.36 988.35 90.49 891.5 43.98 1023.48 88.8 942.75 43.98 1076.17 100.64 960.46 36.36 1102.98 97.25 998.21 43.13" fill="none" stroke={props.color} stroke-linecap="round" stroke-linejoin="round" stroke-width="29.12"/></g></svg>
  );
}

const BorderSmall: React.FC<BorderProps> = (props) => {
  return (
    <svg className={styles.border} preserveAspectRatio="none" viewBox="0 0 1025 137"><defs><clipPath id="clip-path"><rect width="1025" height="137" fill="none"/></clipPath></defs><g clip-path="url(#clip-path)"><polyline points="-672.3 0.58 -362.7 136.42 -635.82 -15.88 -332.07 118.58 -552.99 0.58 -306.32 96.63 -500.23 -15.88 -204.25 122.7 -446 -15.88 -135.66 128.19 -412.28 -29.6 -72.7 121.33 -276.5 6.07 -56.42 98 -249.59 -6.28 35.99 110.35 -231.5 -29.6 82.67 103.49 -102.62 -10.39 144.89 88.4 -32.12 -10.39 220.18 98 -37.31 -44.69 311.31 106.23 87.73 -14.51 357.57 98 140.69 -18.62 413.72 92.51 239.59 -14.51 509.43 98 242.9 -44.69 597.48 102.12 377.09 -20 682.34 107.61 432.5 -33.72 697.77 81.54 512.79 -25.48 798.47 85.65 617.85 -13.14 888.11 100.75 613.5 -44.69 900.24 81.54 770.76 1.96 974.68 88.4 820.64 -6.28 1030.41 81.54 839.47 -21.37 1104.84 88.4 901.04 -26.86 1180.24 92.51 1008.65 -6.28 1245.54 81.54 1088.4 6.07 1302.54 78.79 1171.56 6.07 1388.03 98 1200.28 -6.28 1431.52 92.51 1261.53 4.7" fill="none" stroke={props.color} stroke-linecap="round" stroke-linejoin="round" stroke-width="47.25"/></g></svg>
  );
};