import React from 'react';
import styles from './phone.module.css';
import { matchScreenWidth } from '../../helper/breakpoint';

export interface InstructionPhoneProps {
  src: string
  className?: string
}

export const InstructionsPhone: React.FC<InstructionPhoneProps> = (props) => {
  const phoneClass = matchScreenWidth({
    onDesktop: () => styles.phoneDesktop,
    onPhone: () => styles.phonePhone
  });
  return (
    <img src={props.src} alt="" className={`${styles.phone} ${phoneClass} ${props.className}`}/>
  );
};