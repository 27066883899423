import React from 'react';
import { Page, ContentPadding, PageTitle, PageSubtitle, PagePadding, PageHorizontalPadding, pagePadding } from '../../helper/page';
import styles from './drawings.module.css';
import { HorizontalSpacer, SizedBox, VerticalSpacer } from '../../helper/sizedBox';
import { maxPageWidth, ConstrainedWidth } from '../../helper/constrainedWidth';
import { Drawing } from './drawing';
import { DownloadCallToAction } from '../../helper/downloadCallToAction';
import { Phone, Desktop } from '../../helper/breakpoint';
import album from './drawings/album.png';
import art from './drawings/art.png';
import bird from './drawings/bird.png';
import cake from './drawings/cake.png';
import dog from './drawings/dog.png';
import fox from './drawings/fox.png';
import giraffe from './drawings/giraffe.png';
import legs from './drawings/legs.png';
import lion from './drawings/lion.png';
import planets from './drawings/planets.png';
import spacex from './drawings/spacex.png';
import spider from './drawings/spider.png';
import sunset from './drawings/sunset.png';
import whale from './drawings/whale.png';
import { Column } from '../../helper/column';
import { sep, shuffle } from '../../../helper/list';
import { degToRad } from '../../../helper/angle';
import { appYellow } from '../../helper/colors';

export const drawingsId = 'drawings';

export const Drawings: React.FC = (props) => {
  return (
    <div id={drawingsId}>
      <Page 
        backgroundColor={appYellow}>
          <PagePadding>
            <ConstrainedWidth>
              <PageHorizontalPadding>
                <PageTitle title="What have other players drawn?"/>
                <PageSubtitle subtitle="In just 2 mins our players drew..."/>
              </PageHorizontalPadding>
            </ConstrainedWidth>
            <ContentPadding
              padRight={false}
              padLeft={false}>
              <Carousel/>
            </ContentPadding>
            <Phone>
              <VerticalSpacer multipler={2.5}/>
            </Phone>
            <Desktop>
              <VerticalSpacer multipler={4.5}/>
            </Desktop>
            <ContentPadding
              padTop={false}>
              <CallToAction/>
            </ContentPadding>
          </PagePadding>
      </Page>
    </div>
  );
};

const Carousel: React.FC = (props) => {
  const drawings = drawingsDatas.map(data => 
    <Drawing
      key={data.drawingUrl}
      src={data.drawingUrl}
      title={data.title}
      angleRad={degToRad(data.angleDeg)}
    />
  );
  const randomised = shuffle(drawings);
  const padded = sep(randomised, <HorizontalSpacer/>);

  return (
    <div className={styles.carouselContainer}>
      {/* Add a sized box because using CSS calc and max were not playing together in CarouselStartPadding */}
      <SizedBox width={pagePadding}/>
      <CarouselStartPadding/>
      {padded}
    </div>
  );
};

/**
 * @return sized box that ensures the start of the carousel lines up with the
 *  left hand edge of other elements of the page.
 */
const CarouselStartPadding: React.FC = (props) => {
  return (
    <div style={{
      display: 'inline-block',
      width: `max((100% - ${maxPageWidth})*0.5, 0px)`,
    }}/>
  );
};

/**
 * Asks the user to download the app.
 */
const CallToAction: React.FC = (props) => {
  return (
    <Column>
      <div className={styles.call_to_action}>
        Download now and feature on our <a href="https://www.instagram.com/playdoodledash/">Instagram</a>!
      </div>
      <VerticalSpacer/>
      <DownloadCallToAction/>
    </Column>
  );
};

interface DrawingData {
  drawingUrl: string 
  title: string 
  angleDeg: number 
}

const drawingsDatas: DrawingData[] = [
  {
    drawingUrl: album,
    title: 'Dark Side of The Moon',
    angleDeg: -5.0
  },
  {
    drawingUrl: art,
    title: '"Well, that is what my Orchard looks like" - Mondrian',
    angleDeg: 2.0
  },
  {
    drawingUrl: bird,
    title: 'Splish splash I was taking a bath',
    angleDeg: -1.0
  },
  {
    drawingUrl: cake,
    title: 'Yum yum',
    angleDeg: 87
  },
  {
    drawingUrl: dog,
    title: 'Woof',
    angleDeg: 1.0
  },
  {
    drawingUrl: fox,
    title: "Look mum, I'm balancing the sun on the end of my nose!",
    angleDeg: -1
  },
  {
    drawingUrl: giraffe,
    title: 'Hello there',
    angleDeg: -177
  },
  {
    drawingUrl: legs,
    title: 'Flamingos',
    angleDeg: 137
  },
  {
    drawingUrl: lion,
    title: 'Bad hair day',
    angleDeg: 183
  },
  {
    drawingUrl: planets,
    title: 'Our solar system',
    angleDeg: -3
  },
  {
    drawingUrl: spacex,
    title: 'Is there life on Mars?',
    angleDeg: 43
  },
  {
    drawingUrl: spider,
    title: 'Incy wincy spider',
    angleDeg: -94
  },
  {
    drawingUrl: sunset,
    title: 'Here comes the sun',
    angleDeg: 128
  },
  {
    drawingUrl: whale,
    title: 'Protect the oceans!',
    angleDeg: 178
  },
];