import React from "react";
import { Page, PagePadding, PageHorizontalPadding, PageTitle, ContentPadding } from "../../helper/page";
import { ConstrainedWidth } from "../../helper/constrainedWidth";
import { NavBar } from "../navigation/navBar";
import Terms from "./terms";
import { Attribution } from "../contact/attribution";

export const TermsAndConditions: React.FC = (props) => {
  return (
    <React.Fragment>
      <NavBar />
      <Page padTop={false}>
        <ConstrainedWidth>
          <PagePadding>
            <PageHorizontalPadding>
              <PageTitle title="Terms of Service" color='black'/>
            </PageHorizontalPadding>
            <ContentPadding>
              <Terms/>
            </ContentPadding>
            <Attribution/>
          </PagePadding>
        </ConstrainedWidth>
      </Page>
    </React.Fragment>
  );
}