import React from 'react';
import styles from './downloadCallToAction.module.css';
import googlePlayBadge from './google-play-badge.svg';
import appStoreBadge from './app-store-badge.svg';
import { Row } from './row';
import { HorizontalSpacer, VerticalSpacer } from './sizedBox';
import { Desktop, Phone } from './breakpoint';
import { Column } from './column';

/**
 * AppStore and PlayStore download buttons.
 */
export const DownloadCallToAction: React.FC = (props) => {
  return (
    <React.Fragment>
      <Desktop>
        <Row>
          <AppleStore/>
          <HorizontalSpacer/>
          <GooglePlay/>
        </Row>
      </Desktop>
      <Phone>
        <Column>
          <AppleStore/>
          <VerticalSpacer/>
          <GooglePlay/>
        </Column>
      </Phone>
    </React.Fragment>
  );
};

const GooglePlay: React.FC = (props) => {
  const googlePlayId = 'com.bakersmitha.scribbling';
  return (
    <a href={`https://play.google.com/store/apps/details?id=${googlePlayId}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}>
      <img className={styles.badge} alt='Get it on Google Play' src={googlePlayBadge}/>
    </a>
  );
};

const AppleStore: React.FC = (props) => {
  return (
    <a href={`https://apps.apple.com/us/app/doodle-dash/id1500126906`}>
      <img className={styles.badge} alt='Download on the App Store' src={appStoreBadge}/>
    </a>
  );
};